import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializer } from '../utils/htmlSerializer';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import FullWidthContainer from '../components/containers/fullWidthContainer';
import LinkButton from '../components/linkButton';
import SectionTitle from '../components/sectionTitle';
import HeroContent from '../components/heroContent';
import { linkResolver } from '../utils/linkResolver';
import FullWidthImageCollection from '../components/images/fullWidthImageCollection';
import { convertToBgImage } from 'gbimage-bridge';
import { RichText } from 'prismic-reactjs';
import { paragraphHtmlSerializer } from '../utils/paragraphHtmlSerializer';
import { hyperlinkHtmlSerializer } from '../utils/hyperlinkHtmlSerializer';
import { phoneNumberHtmlSerializer } from '../utils/phoneNumberHtmlSerializer';

const mediaInfoContent = (primary, fields) => {
  const title = RichText.asText(primary.media_info_title.richText);

  const mainImageField = fields[0];
  const secondaryImageFields = fields.slice(1, fields.length);

  return (
    <div className='w-full text-blue-100'>
      <SectionTitle text={title} useWhiteTextColor />
      <div className='mx-auto max-w-7xl px-6 text-left lg:px-24 lg:text-justify mega:px-48'>
        <PrismicRichText
          field={primary.media_text_content.richText}
          components={htmlSerializer}
        />
      </div>

      <div className='lg:flex-no-wrap flex w-full flex-col items-center justify-center lg:flex-row'>
        <div className='w-full lg:w-3/5 giant:w-1/2'>
          <a
            href={mainImageField.media_info_image.url}
            rel='noopened noreferrer'
            target='_blank'
          >
            <GatsbyImage
              image={mainImageField.media_info_image.gatsbyImageData}
              alt={mainImageField.media_info_image.alt}
              className='h-full object-cover shadow-lg lg:mr-2'
            />
          </a>
        </div>
        <div className='flex w-full flex-col lg:w-2/5 giant:w-1/2'>
          {secondaryImageFields.map((field, index) => {
            const imageFluid = field.media_info_image.gatsbyImageData;
            const url = field.media_info_image.url;
            const alt = field.media_info_image.alt;
            return (
              <a
                key={index}
                href={url}
                rel='noopened noreferrer'
                target='_blank'
              >
                <GatsbyImage
                  image={imageFluid}
                  alt={alt}
                  className='my-2 h-full object-cover shadow-lg lg:ml-2 lg:h-64'
                />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const BodySectionLarge = ({ images, title, description }) => {
  return (
    <section className='hidden bg-white lg:block mega:py-8'>
      <SectionTitle text={title} usePrimaryTextColor />
      <FullWidthImageCollection
        className='sm:bottom-slant-lg'
        images={images}
      />
      <div className='top-slant mega:top-slant-mega -mt-8 mb-8 flex flex-col items-center bg-denimblue px-48 pb-12 text-justify text-white md:-mt-16 lg:-mt-24 giant:-mt-32'>
        <div className='mt-8 md:mt-12 lg:mt-20'>
          <PrismicRichText
            field={description}
            components={{
              paragraph: paragraphHtmlSerializer,
              hyperlink: hyperlinkHtmlSerializer,
            }}
          />
        </div>
      </div>
    </section>
  );
};

const BodySectionSmall = ({ images, title, description }) => {
  return (
    <section className='bg-white lg:hidden mega:py-8'>
      <SectionTitle text={title} usePrimaryTextColor />
      <div className='top-slant mt-4 mb-8 flex flex-col bg-denimblue px-6 pt-16 pb-8 text-left text-white'>
        <PrismicRichText
          field={description}
          components={{
            paragraph: paragraphHtmlSerializer,
            hyperlink: hyperlinkHtmlSerializer,
          }}
        />
      </div>
      <FullWidthImageCollection
        className='lg:bottom-slant-lg'
        images={images}
      />
    </section>
  );
};

const AdmissionsPage = ({ data }) => {
  const {
    banner_image,
    banner_graphic,
    banner_description_content,
    body,
    bottom_section_title,
    bottom_section_text,
    bottom_images,
    middle_images,
    middle_section_title,
    middle_section_text,
    top_section_title,
    top_section_text,
    title,
    next_page_link,
    call_to_action_link_text,
  } = data.prismicAdmissions.data;

  const backgroundImage = convertToBgImage(banner_image.gatsbyImageData);
  const backgroundImageFluidStack = [`${banner_graphic.url}`, backgroundImage];

  const ctaLink = linkResolver(next_page_link);

  return (
    <Layout
      pageTitle={RichText.asText(title.richText)}
      backgroundImageFluidStack={backgroundImageFluidStack}
      logoClassName='max-w-xxxxs xl:max-w-xxxs mega:max-w-xxs'
      heroSlantClassName='hero-slant lg:hero-slant-lg'
      contentInsideBackground={() => (
        <FullWidthContainer className='xl:mt-6 mega:mt-16'>
          <HeroContent
            heroTitle={RichText.asText(title.richText)}
            heroDescription={banner_description_content.richText}
            rightAlign
          />
        </FullWidthContainer>
      )}
    >
      <div className='mx-auto my-8 max-w-7xl px-6 lg:px-32 xl:mt-16 mega:mt-32 mega:px-48'>
        <div className='text-center text-xl font-semibold uppercase text-denimblue mega:text-3xl'>
          <SectionTitle
            text={RichText.asText(top_section_title.richText)}
            usePrimaryTextColor
          />
        </div>
        <div className='my-6 leading-relaxed text-gray-700'>
          <PrismicRichText
            field={top_section_text.richText}
            components={htmlSerializer}
          />
        </div>
      </div>
      <div className='left-angled-down-right-top-only my-8 flex flex-col items-center bg-blue-800 py-12 text-center md:px-6 lg:py-24 mega:py-32'>
        {mediaInfoContent(body[0].primary, body[0].items)}
      </div>
      <BodySectionLarge
        images={middle_images}
        title={RichText.asText(middle_section_title.richText)}
        description={middle_section_text.richText}
      />
      <BodySectionSmall
        images={middle_images}
        title={RichText.asText(middle_section_title.richText)}
        description={middle_section_text.richText}
      />
      <div className='mx-auto mb-8 max-w-7xl px-6 lg:my-24 lg:px-32 mega:px-48 giant:my-32'>
        <div className='mb-3 text-center text-lg font-semibold uppercase text-denimblue lg:mb-6'>
          <SectionTitle
            text={RichText.asText(bottom_section_title.richText)}
            usePrimaryTextColor
          />
        </div>
        <div className='text-gray-700'>
          <PrismicRichText
            field={bottom_section_text.richText}
            components={{
              paragraph: ({ children }) => {
                if (
                  children &&
                  children.length > 0 &&
                  children[0] &&
                  children[0].type === 'strong'
                ) {
                  return <p className='my-2 leading-relaxed'>{children}</p>;
                } else {
                  return <p className='my-6 leading-relaxed'>{children}</p>;
                }
              },
              hyperlink: phoneNumberHtmlSerializer,
            }}
          />
        </div>
      </div>
      <div className='top-slant mega:top-slant-mega -mt-4 mb-8 flex flex-col items-center bg-denimblue px-2 pt-8 text-center text-sm text-white lg:-mt-12 lg:pt-12 lg:text-base'>
        <div className='my-12 w-full text-center md:w-1/3'>
          <LinkButton
            to={ctaLink.url}
            label={RichText.asText(call_to_action_link_text.richText)}
          />
        </div>
      </div>
      <div className='py-2'>
        <FullWidthImageCollection
          className='-mt-12 lg:mt-0'
          images={bottom_images}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AdmissionsPageQuery {
    prismicAdmissions {
      data {
        banner_description_content {
          richText
        }
        banner_graphic {
          url
          alt
          gatsbyImageData
        }
        banner_image {
          alt
          gatsbyImageData
        }
        bottom_images {
          image {
            alt
            gatsbyImageData
            url
          }
        }
        bottom_section_text {
          richText
        }
        bottom_section_title {
          richText
        }
        call_to_action_link_text {
          richText
        }
        middle_images {
          image {
            gatsbyImageData
            alt
          }
        }
        middle_section_text {
          richText
        }
        middle_section_title {
          richText
        }
        next_page_link {
          uid
          url
        }
        title {
          richText
        }
        top_section_text {
          richText
        }
        top_section_title {
          richText
        }
        body {
          ... on PrismicAdmissionsDataBodyMediaSupportedInfoBlock {
            primary {
              media_info_title {
                richText
              }
              media_text_content {
                richText
              }
            }
            items {
              media_info_image {
                gatsbyImageData
                alt
              }
            }
          }
        }
      }
    }
  }
`;

export default AdmissionsPage;
