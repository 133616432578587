import React from 'react';

export const hyperlinkHtmlSerializer = ({ children, node }) => {
  return (
    <a
      className='rounded bg-white px-2 py-1 text-denimblue hover:underline'
      href={node.data.url}
    >
      {children}
    </a>
  );
};

export const hyperlinkHtmlSerializerWhiteBackground = ({ children, node }) => {
  return (
    <a
      className='rounded bg-white px-2 py-1 text-denimblue hover:underline'
      href={node.data.url}
    >
      {children}
    </a>
  );
};
