import React from 'react';

export const paragraphHtmlSerializer = ({ children }) => {
  if (
    children &&
    children.length > 0 &&
    children[0] &&
    children[0].type === 'strong'
  ) {
    return <p className='my-6 pt-8 text-2xl leading-relaxed'>{children}</p>;
  } else {
    return <p className='my-4 leading-relaxed'>{children}</p>;
  }
};
